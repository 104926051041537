import React, { useMemo, useState, useEffect } from 'react';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import Card from 'components/card';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { MdChevronLeft, MdChevronRight, MdDeleteForever } from 'react-icons/md';
import { remove, get } from 'api';
import { FiSearch } from 'react-icons/fi';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IoMdFunnel } from 'react-icons/io';
import TooltipHorizon from 'components/tooltip';

const SuggestionsTable = (props) => {
  const { columnsData } = props;
  const [projectsList, setProjectsList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const filteredSuggestionsPerProject = useMemo(() => {
    if (selectedProject) {
      return suggestions.filter((item) => item.projectId === selectedProject);
    }
    return suggestions;
  }, [suggestions, selectedProject]);

  useEffect(() => {
    const getProjectsList = async () => {
      await get(`/api/project/projects-list`).then((res) =>
        res.json().then((data) => {
          setProjectsList(data);
        }),
      );
    };
    getProjectsList();
  }, []);

  const getSuggestion = async () => {
    await get(
      `/api/suggestions?page=${currentPage}&limit=${limit}&searchTerm=${searchTerm}`,
    ).then((res) =>
      res.json().then((data) => {
        setTotalPages(data.totalPages);
        setTotalItems(data.total);
        const newData = data.data.map((item) => {
          return {
            ...item,
            projectName: item.project.projectName,
            projectDomain: item.project.domainName,
            suggestionDomain: item.marketplace.domain,
          };
        });

        newData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

        setSuggestions(newData);
      }),
    );
  };

  useEffect(() => {
    getSuggestion();
  }, [currentPage, limit, searchTerm]);

  const handleRemoveSuggestion = async (id) => {
    if (window.confirm('Are you sure you want to delete this suggestion?')) {
      await remove(`/api/suggestions/${id}`).then((res) => {
        if (res.status === 200) {
          toastSuccess('Suggestion deleted successfully');
          getSuggestion();
        } else {
          toastError('Something went wrong');
        }
      });
    }
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(
    () => filteredSuggestionsPerProject,
    [filteredSuggestionsPerProject],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  const handleSelectProject = (e) => {
    if (e.value === 'all') {
      setSelectedProject(null);
    } else {
      setSelectedProject(e.value);
    }
  };

  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      <Select
        className="mb-4 w-1/4"
        options={[
          { value: 'all', label: 'Select All' },
          ...projectsList.map((item) => ({
            value: item.id,
            label: item.projectName,
          })),
        ]}
        onChange={(e) => handleSelectProject(e)}
        placeholder="Select project"
      />
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Search */}
      <div
        onChange={(e) => {
          setTimeout(() => {
            setSearchTerm(e.target.value);
          }, 1000);
        }}
        className="flex items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mt-2 md:w-3/4 md:w-[400px]"
      >
        <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search...."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse whitespace-nowrap"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pb-[10px] pr-8 dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      <div className="flex items-center gap-2">
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BsChevronUp className="mr-1 inline h-4 w-4" />
                          ) : (
                            <BsChevronDown className="mr-1 inline h-4 w-4" />
                          )
                        ) : (
                          <IoMdFunnel className="mr-1 inline h-4 w-4" />
                        )}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {suggestions.length === 0 && (
              <tr>
                <td
                  colSpan="7"
                  className="pt-4 text-center text-sm font-bold text-gray-700 dark:text-white"
                >
                  There are no suggestions yet
                </td>
              </tr>
            )}
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="items-center border-b border-gray-200 dark:!border-white/10"
                >
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'PROJECT ID') {
                      data = (
                        <div className="w-[60px] text-sm font-bold text-navy-700 dark:text-white">
                          #{cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'PROJECT NAME') {
                      data = (
                        <div className="w-[180px] text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'PROJECT DOMAIN') {
                      data = (
                        <a
                          href={cell.value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TooltipHorizon
                            extra="max-h-[160px] overflow-y-auto"
                            trigger={
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value && cell.value.slice(0, 20)}
                                {cell.value && cell.value.length > 20 && (
                                  <span>...</span>
                                )}
                              </p>
                            }
                            content={
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value}
                              </p>
                            }
                            placement="top"
                          />
                        </a>
                      );
                    } else if (cell.column.Header === 'SUGGESTION ID') {
                      data = (
                        <div className="w-[100px] text-sm font-bold text-navy-700 dark:text-white">
                          #{cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'SUGGESTION DOMAIN') {
                      const domain = cell.value;
                      data = (
                        <a
                          href={cell.value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TooltipHorizon
                            trigger={
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {domain && domain.slice(0, 30)}
                                {domain && domain.length > 30 && (
                                  <span>...</span>
                                )}
                              </p>
                            }
                            content={
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {domain}
                              </p>
                            }
                            placement="top"
                          />
                        </a>
                      );
                    } else if (cell.column.Header === 'STATUS') {
                      const cellValue = cell.value;
                      data = (
                        <div
                          className={`mr-4 flex h-7 w-[100px] items-center justify-center text-sm ${
                            cellValue === 'ordered'
                              ? 'bg-green-100 dark:bg-green-50'
                              : cellValue === 'suggested'
                              ? 'bg-yellow-100 dark:bg-yellow-50'
                              : 'bg-red-100 dark:bg-red-50'
                          } rounded-[10px] text-base font-bold `}
                        >
                          <div
                            className={`${
                              cellValue === 'ordered'
                                ? 'text-green-500 '
                                : cellValue === 'suggested'
                                ? 'text-yellow-500'
                                : 'text-red-500'
                            } uppercase `}
                          >
                            {cellValue}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'REASON') {
                      const reason = cell.value === null ? '-' : cell.value;
                      data = (
                        <TooltipHorizon
                          trigger={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {reason && reason.slice(0, 30)}
                              {reason && reason.length > 30 && <span>...</span>}
                            </p>
                          }
                          content={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {reason}
                            </p>
                          }
                          placement="top"
                        />
                      );
                    } else if (cell.column.Header === 'DELETE') {
                      const itemId = cell.row.original.id;
                      data = (
                        <button
                          onClick={() => handleRemoveSuggestion(itemId)}
                          className="text-2xl font-bold text-navy-700 hover:text-red-500 dark:text-white dark:hover:text-red-500"
                        >
                          <MdDeleteForever />
                        </button>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pb-[16px] pt-[14px] sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-10 flex h-[80px] w-full items-center justify-between px-6">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setLimit(Number(e.target.value));
            }}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {currentPage * limit} of {totalItems} entries
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => {
              previousPage();
              setCurrentPage(currentPage === 1 ? 1 : currentPage - 1);
            }}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            Page {currentPage} of {totalPages}
          </p>

          <button
            onClick={() => {
              nextPage();
              setCurrentPage(
                currentPage === totalPages ? totalPages : currentPage + 1,
              );
            }}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default SuggestionsTable;
